<template>
  <huboo-page>
    <huboo-page-heading
      :heading="$t('defaultPickRateNumberPricing.navTitle') | title"
    ></huboo-page-heading>
    <huboo-table
      id="defaultPickRateNumberPricing"
      hide-date-picker
      hide-search
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading"
      @row-clicked="onRowSelected"
      @update:options="handleUpdateOptions"
      @confirm-delete="onRemove"
      @remove="openConfirmationModal"
      :remove="isAdmin"
      localPagination
    >
      <template #actions>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="additionalPricingCreateButton"
          v-if="isAdmin"
        >
          {{ $t('defaultPickRateNumberPricing.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>
    <edit-modal
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <create-modal v-model="createModal" @close="closeCreateModal" @updated-item="onUpdatedItem" />
  </huboo-page>
</template>

<script>
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import VersionedPriceListMixin from '@/mixins/versionedPriceList'
import editModal from './DefaultPickRateNumberPricingEditModal.vue'
import createModal from './DefaultPickRateNumberPricingCreateModal.vue'

const tableHeaders = [
  {
    text: title(i18n.t('defaultPickRateNumberPricing.fields.pickNumber')),
    sortable: false,
    value: 'pickNumber',
  },
  {
    text: title(i18n.t('defaultPickRateNumberPricing.fields.itemRatePrice')),
    sortable: false,
    value: 'itemRatePrice',
  },
  {
    text: title(i18n.t('defaultPickRateNumberPricing.fields.currency')),
    sortable: false,
    value: 'currency',
  },
  {
    text: title(i18n.t('defaultPickRateNumberPricing.fields.originRegion')),
    sortable: false,
    value: 'originRegion',
  },
  {
    text: title(i18n.t('defaultPickRateNumberPricing.fields.updatedAt')),
    sortable: false,
    value: 'updatedAt',
  },
]

export default {
  name: 'DefaultPickRateNumberPricing',
  mixins: [ClientMixin, ModalActionsMixin, VersionedPriceListMixin],
  components: {
    'edit-modal': editModal,
    'create-modal': createModal,
  },
  data() {
    return {
      editModal: false,
      createModal: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      selected: null,
    }
  },

  computed: {
    headers() {
      return tableHeaders
    },

    defaultPickRateNumberPricelist() {
      const list = this.$store.getters['defaultPickRateNumberPricing/getAll']
      if (Array.isArray(list))
        return list.map(l => ({
          ...l,
          loading: this.isDeleteLoading(l.id),
        }))
      else return []
    },

    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },

    items() {
      return this.defaultPickRateNumberPricelist.map(item => {
        return {
          ...item,
          updatedAt: this.formatDate(item.updatedAt),
        }
      })
    },

    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },

    meta() {
      return (
        this.$store.getters['defaultPickRateNumberPricing/getMeta'] || { total: this.items.length }
      )
    },

    url() {
      return '/pick-rate-number-default-price-list/'
    },

    dataReady() {
      return !this.loading && this.getSelectedPriceListVersionId
    },
  },

  methods: {
    fetch() {
      if (this.dataReady) {
        const { options } = this
        const { page } = options
        const params = {
          page: page || undefined,
          priceListVersion: this.getSelectedPriceListVersionId,
        }
        this.$store.dispatch('defaultPickRateNumberPricing/fetchCollection', {
          params,
        })
      }
    },

    onRemove() {
      this.$store.dispatch('defaultPickRateNumberPricing/delete', this.selected.id).finally(() => {
        if (!this.hasErrors) this.fetch()
        this.selected = ''
      })
    },

    isDeleteLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.url + id,
        method: 'DELETE',
      })
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },

    getSelectedPriceListVersionId() {
      this.fetch()
    },
  },
}
</script>

<style lang="scss" scoped></style>
